"use client";
import { motion, MotionProps } from "framer-motion";
import React, { useRef, useEffect, useState } from "react";

const AnimateBoxSlideDown = ({
  children,
  delay = 0,
  margin = "-100px",
}: {
  children: React.ReactNode;
  delay?: number;
  margin?: string;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: margin,
      threshold: 0.1, // Możesz dostosować ten próg dla większej precyzji
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Jeśli animacja ma być jednokrotna
        }
      });
    }, observerOptions);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [margin]);

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 100 }}
      animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 200 }}
      transition={{
        duration: 0.5,
        delay: delay,
      }}
    >
      {children}
    </motion.div>
  );
};

export default AnimateBoxSlideDown;
