"use client";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import opinionImg from "../../public/opinion-img.png";
import Image from "next/image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const opinions = [
  {
    id: 1,
    author: "Asia Dworak",
    title: "Podopieczna",
    img: opinionImg,
    text: "Gdy zobaczyłam ogłoszenie o AW-Sport Class stwierdziłam, że chętnie spróbowałabym czegoś innego niż do tej pory. Zaczęłam trenować na siłowni pod okiem Damiana w małej grupie. Okazało się, że trening siłowy nie tylko nie jest aż taki trudny jak mi się wydawało, ale również daje ogromną satysfakcję. Wsparcie innych osób, wzajemna motywacja oraz profesjonalna opieka trenera przełożyły się na świetne rezultaty w postaci świetnego samopoczucia i wyglądu.",
  },
  {
    id: 2,
    author: "Grzesiek Fluda",
    title: "Podopieczny",
    img: opinionImg,
    text: "Z wydarzeniem AW-sport Camp cztery razy było mi nie po drodze. Wynikało to z kolizji w kalendarzu z wcześniej zaplanowanym wydarzeniami. Ale V edycja to było to, fajne atrakcje sportowe, fajne miejsce i cudowni wartościowi uczestnicy. Dzięki Campowa Ekipo! 💪😎🤙",
  },
  {
    id: 3,
    author: "Grzegorz Dworak",
    title: "Podopieczny",
    img: opinionImg,
    text: "Jako programista siedzę godzinami przy biurku, a energia do zabawy z dzieciakami gdzieś mi się ulatniała. Tenis zawsze sprawiał mi frajdę, ale brakowało mi mocy, żeby naprawdę dać z siebie wszystko na korcie. Wtedy wkroczyła moja żona – zaczęła chodzić na siłownię i bardzo szybko było widać efekty. “Chodź ze mną, spróbuj!” – powiedziała. No i miała rację! </br> </br> Dzięki regularnym treningom mam więcej siły, co wyraźnie czuć na korcie, a i z dzieciakami mogę się teraz bawić bez zadyszki. ",
  },
  {
    id: 4,
    author: "Ela Rokosz",
    title: "Podopieczna",
    img: opinionImg,
    text: "Treningi personalne zaczęłam trochę przypadkowo, na skutek zbiegu różnych okoliczności, ponad trzy lata temu. Trzymam się ich – i mojego trenera 😊 – bo w mojej grupie wiekowej nie chodzi już o wymodelowanie sylwetki, ale o to, by utrzymać ogólną sprawność. Trening personalny pozwala na dostosowanie ćwiczeń do bieżących, indywidualnych potrzeb. Co się popsuje, to próbujemy naprawić 😊I póki co się udaje 😊",
  },
  {
    id: 5,
    author: "Kamila Mokrzycka",
    title: "Podopieczna",
    img: opinionImg,
    text: "Miałam nigdy nie polubić siłowni, poznałam chłopaków, zobaczyłam, że można trenować z pasją, rozsądkiem, świadomością i z normalnym podejściem. Nawet nie zauważyłam kiedy minęła moja rocznica z siłownią.",
  },
  {
    id: 6,
    author: "Natalia Chorzępa",
    title: "Podopieczna",
    img: opinionImg,
    text: "Od wielu lat chodziłam na siłownię, ale tylko na zajęcia grupowe. Unikałam maszyn i sprzętów, gdyż nie umiałam prawidłowo z nich korzystać. Nie miałam pojęcia jak powinien wyglądać dobry trening. Brakowało mi też techniki i dokładności w wykonywaniu ćwiczeń. Dokładnie rok temu podjęłam decyzję, że potrzebuję treningu indywidualnego. Na swojego Trenera wybrałam Damiana i dziś śmiało mogę stwierdzić, że trafiłam w ręce Profesjonalisty 😊",
  },
  {
    id: 7,
    author: "Mirek Szpyrka",
    title: "Podopieczny",
    img: opinionImg,
    text: "Trenuję na siłowni pod czujnym okiem Damiana, ponieważ od dłuższego czasu zmagam się z bólem pleców, wynikającym z mojej pracy jako muzyk. Długie godziny spędzane na graniu na instrumencie oraz częste podróże za kierownicą samochodu znacząco wpływały na mój dyskomfort i samopoczucie. <br/> <br/> Od kiedy rozpocząłem treningi z Damianem, zauważyłem ogromną poprawę. Ból pleców ustąpił, a ja czuję się znacznie lepiej i mam więcej energii. Damian planuje moje treningi w sposób przemyślany i indywidualny, dobierając ćwiczenia odpowiednie do moich potrzeb i możliwości.",
  },
  {
    id: 8,
    author: "Karolina Korzępa",
    title: "Podopieczna",
    img: opinionImg,
    text: "Na pierwszy camp wybrałam się tak naprawdę z ciekawości To czego doświadczyłam na wyjeździe przeszło moje najśmielsze oczekiwania. Chłopaki potrafią stworzyć przyjazną, pełną luzu atmosferę. Największą wartością wynikającą z uczestnictwa w AW-Sport Campach jest to, że poznałam wspaniałych przyjaciół i pokochałam aktywne spędzanie wolnego czasu. Serdecznie polecam wszystkim współpracę z Damianem i Krzychem ♥️",
  },
  {
    id: 9,
    author: "Gabriela Rejch",
    title: "Podopieczna",
    img: opinionImg,
    text: "Najlepszy team AW-Sport to pełen profesjonalizm, ogromna wiedza i  zawsze gwarancja niesamowitego poczucia humoru! Trenerzy robią wszystko by osiągnąć założone cele, niesamowicie zależy im na uczestnikach treningów. </br> </br> Z całego serca i wszystkich mięśni, o których istnieniu pojęcia nie miałam - polecam. Zyskałam nie tylko lepszy wygląd, ale i zdrowie oraz pewność siebie. </br> </br> Polecam Atomówka Gabka 👌",
  },
];

const OpinionSlider = () => {
  const [paddingLeft, setPaddingLeft] = useState(0);

  useEffect(() => {
    function onResize() {
      if (window.innerWidth > 1520) {
        if (window.innerWidth > 1920) {
          setPaddingLeft(200);
        } else {
          setPaddingLeft((window.innerWidth - 1520) / 2);
        }
      } else {
        setPaddingLeft(0);
      }
    }

    window.addEventListener("resize", onResize);
    onResize();
    return () => window.removeEventListener("resize", onResize);
  }, []);

  const settings = {
    infinite: true,
    speed: 1000,
    autoplaySpeed: 5000,
    slidesToShow: 4,
    autoplay: true,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div
      className={`mt-16 max-w-[1920px] mx-auto relative`}
      style={{ paddingLeft: `${paddingLeft}px` }}
    >
      <div className="bg-opinions-gradient absolute right-0 top-0 h-full w-[100px] lg:w-[340px] z-20"></div>
      <div className="slider-container">
        <div style={{}}>
          <Slider
            {...settings}
            className="[&_.slick-track]:flex [&_.slick-track]:gap-4 cursor-pointer"
          >
            {opinions.map(({ text, id, author, img, title }, idx) => {
              return (
                <div key={`${idx}-${id}`} className="py-7 px-10 bg-awDark">
                  <p
                    className="text-base mb-8 font-light text-white"
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                  <div className="flex gap-4">
                    <div className="flex flex-col ">
                      <span className="text-awSea font-semibold">{author}</span>
                      <span className="text-awGrey font-normal">{title}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default OpinionSlider;
